import React, { useState, useEffect } from "react";
import "../styles/main_styles.css";
import "../styles/responsive.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toast, { Toaster } from "react-hot-toast";
// import LocationPicker from "./LocationPicker";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
  MDBSwitch,
} from "mdb-react-ui-kit";
import Form from "react-bootstrap/Form";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";
import Cookies from "js-cookie";
import axios from "axios";
// import LocationPicker from "./MapComponent";

const Home = () => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [requests, setRequests] = useState([]);

  const [categoryOption, setCategoryOption] = useState("");
  const [isdisable, setIsdisable] = useState(false);

  const handlecategoryChange = (e) => {
    setCategoryOption(e.target.value);
  };

  useEffect(() => {
    fetchEventStatuses();
  }, [Cookies.get("email")]);

  const fetchEventStatuses = async () => {
    try {
      const email = Cookies.get("email"); // Replace 'email' with the actual cookie name
      const response = await fetch(
        `${process.env.REACT_APP_URI}/GetEventIdsRequest?email=${email}`
      );
      const result = await response.json();
      setRequests(result.data);
    } catch (error) {
      console.error("Error fetching event statuses:", error);
    }
  };

  const handlePostClick = (postTitle, postId) => {
    // Log event to Google Analytics
    window.gtag("event", "post_click", {
      event_category: "User Interaction",
      event_label: postTitle,
      value: postId,
      post_clicks: postId,
    });
  };

  const handleSort = () => {
    let sortedData = [...data];
    console.log(categoryOption);
    switch (categoryOption) {
      case "Location (Increasing Order)":
        sortedData.sort((a, b) => a.location.localeCompare(b.location));
        break;
      case "Location (Decreasing Order)":
        sortedData.sort((a, b) => b.location.localeCompare(a.location));
        break;
      case "Date (Increasing Order)":
        sortedData.sort((a, b) => new Date(a.date) - new Date(b.date));
        break;
      case "Date (Decreasing Order)":
        sortedData.sort((a, b) => new Date(b.date) - new Date(a.date));
        break;
      default:
        break;
    }

    setData(sortedData);
  };

  // Make sure to use the originalData for resetting the data when needed
  const resetData = () => {
    setData(originalData);
  };

  // You can call resetData if you want to go back to the original data
  // resetData();

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const response = await axios.get(
          `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
            query
          )}&key=7c3db7d1298a49ac8e3962fbf93dad32`
        );

        setResults(response.data.results);
        console.log(response.data.results);
      } catch (error) {
        console.error("Error fetching location data:", error);
      }
    };

    // Fetch location data only if the query is not empty
    if (query.trim() !== "") {
      fetchLocationData();
    }
  }, [query]);

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion.formatted);
    // You can perform additional actions when a suggestion is clicked
  };

  // const [location, setLocation] = useState(null);

  // const handleLocationChange = (newLocation) => {
  //   setLocation(newLocation);
  // };

  const [maxHeight, setMaxHeight] = useState("700px");
  const [basicModal, setBasicModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  function logout() {
    Cookies.remove("login");
    Cookies.remove("email");
    Cookies.remove("token");
    window.location.href = "/";
  }

  useEffect(() => {
    GetEvents();
    GetLocations();
    const handleResize = () => {
      const minWidth = 300;
      const maxWidth = 800;
      const maxHeight = 700;

      const currentWidth = window.innerWidth;

      // Calculate the percentage of the width between minWidth and maxWidth
      const percentage = Math.min(
        100,
        Math.max(0, ((currentWidth - minWidth) / (maxWidth - minWidth)) * 100)
      );

      // Calculate the new height based on the percentage with a maximum of 700px
      const newHeight = `${Math.min(650, 300 + (percentage / 100) * 500)}px`;

      setMaxHeight(newHeight);
    };

    // Initial call to set max height based on the initial width
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [hide, setHide] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);

    const form = e.target;
    const formData = new FormData(form);
    formData.append("hide", hide); // Add hide field to FormData

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URI}/AddEvent?email=${Cookies.get(
          "email"
        )}&latitude=${results[0].geometry.lat}&longitude=${
          results[0].geometry.lng
        }`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      form.reset();
      GetEvents();
      setSubmit(false);
      setBasicModal(false);
    } catch (error) {
      console.error("Error:", error.message);
      setSubmit(false);
    }
  };

  const [data, setData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  async function GetEvents() {
    setLoad(true);
    await fetch(`${process.env.REACT_APP_URI}/GetEvents`, {
      method: "GET",
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        setData(data.data);
        setOriginalData(data.data);
        setLoad(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  async function GetLocations() {
    await fetch(`${process.env.REACT_APP_URI}/GetLocations`, {
      method: "GET",
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        setLocations(data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const handleSearch = async () => {
    var lat = 0;
    var long = 0;
    console.log(selectedOption);

    const selectedData = originalData.find(
      (data) => data.location == selectedOption
    );
    console.log(selectedData);

    // Update latitude and longitude states based on the selected data
    if (selectedData) {
      lat = selectedData.latitude;
      long = selectedData.longitude;
    } else {
      // Handle the case when the selected option is not found in the originalData
      lat = 0;
      long = 0;
    }
    // eslint-disable-next-line no-undef
    const filteredData = originalData.filter((event) => {
      const isLocationMatch =
        selectedOption === "" ||
        isLocationWithinRange(event, selectedOption, lat, long);

      // Updated condition to check if the date is equal
      const isDateMatch =
        selectedDate === null ||
        new Date(event.date).toDateString() === selectedDate.toDateString();

      const isTextMatch =
        searchQuery === "" ||
        event.title.toLowerCase().includes(searchQuery.toLowerCase());

      return isLocationMatch && isDateMatch && isTextMatch;
    });

    console.log(filteredData);
    setData(filteredData);
  };

  // Function to handle the request to join
  const handleRequestToJoin = async (eventId) => {
    try {
      // Retrieve email from cookies
      const email = Cookies.get("email"); // Replace 'email' with the actual cookie name

      if (!email) {
        toast.error("Email is required to request to join.");
        return;
      }

      // Prepare the data to be sent
      const requestData = {
        eventId,
        email,
      };

      // Send POST request
      const response = await axios.post(
        `${process.env.REACT_APP_URI}/RequestToJoin`,
        requestData
      );

      // Handle response
      console.log("Request successful:", response.data);
      toast.success("Request to join sent successfully.");
      fetchEventStatuses();
    } catch (error) {
      console.error("Error sending request to join:", error);
      toast.error("Failed to send request to join.");
    }
  };

  const isLocationWithinRange = (event, selectedOption, lat, long) => {
    const isLatitudeWithinRange = Math.abs(event.latitude - lat) <= 1;
    const isLongitudeWithinRange = Math.abs(event.longitude - long) <= 1;

    return isLatitudeWithinRange && isLongitudeWithinRange;
  };

  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (event) => {
    // setTimeout(() => {
    // setIsdisable(true);
    // }, 1000);
    setSelectedOption(event.target.value);
    console.log(event.target.value);
    // setIsdisable(false);
    // document.getElementById('dropdown').disabled = false;
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    console.log("Menu Open?", menuOpen); // Debugging line
  };



  if (load) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <MDBSpinner grow style={{ color: "#0aae4d" }}>
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
      </div>
    );
  }

  return (
    <div>
      <div class="super_container">
        <header class="header">
          <div class="header_content d-flex flex-row align-items-center justify-content-end">
            <div
              class="logo"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                window.location.href = "/";
              }}
            >
              <img
                src="../assets/logo.png"
                style={{ width: "60px", height: "60px" }}
                alt=""
              />
              <a href="/" style={{ marginLeft: "20px" }}>
                Boongombia
              </a>
            </div>
            <div class="log_reg">
              {Cookies.get("login") === "true" ? (
                <ul class="d-flex flex-row align-items-start justify-content-start">
                  <li>
                    <a
                      onClick={() => logout()}
                      style={{ color: "#FFF", cursor: "pointer" }}
                    >
                      Logout
                    </a>
                  </li>
                  <li>
                    {Cookies.get("login") === "true" ? (
                      <a
                        onClick={() => setBasicModal(true)}
                        style={{ color: "#FFF", cursor: "pointer" }}
                      >
                        Add Event
                      </a>
                    ) : (
                      <a href="/Login" style={{ color: "#FFF" }}>
                        Add Event
                      </a>
                    )}
                  </li>
                </ul>
              ) : (
                <ul class="d-flex flex-row align-items-start justify-content-start">
                  <li>
                    {Cookies.get("login") === "true" ? (
                      <a onClick={() => setBasicModal(true)}>Add Event</a>
                    ) : (
                      <a href="/Login">Add Event</a>
                    )}
                  </li>
                  <li>
                    <a href="/Login">Login</a>
                  </li>
                  <li>
                    <a href="/SignUp">Register</a>
                  </li>
                </ul>
              )}
            </div>
            <nav class="main_nav" style={{ marginRight: "200px" }}>
              <ul class="d-flex flex-row align-items-start justify-content-start">
                <li class="active">
                  <a href="/">Home</a>
                </li>
                {Cookies.get("login") === "true" ? (
                  <li>
                    <a href="/Chat">Chat</a>
                  </li>
                ) : null}

                <li>
                  <a href="/Contact">Contact</a>
                </li>
                {Cookies.get("login") === "true" ? (
                  <li>
                    <a href="/Profile">Profile</a>
                  </li>
                ) : null}
              </ul>
            </nav>
            <div class="hamburger ml-auto" onClick={toggleMenu}>
              <div class="d-flex flex-column align-items-end justify-content-between">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </header>

        <div className={`menu ${menuOpen ? "active" : ""}`}>
          <div class="menu_overlay" onClick={()=>{setMenuOpen(false)}}></div>
          <div class="menu_container d-flex flex-column align-items-start justify-content-center">
            <div class="menu_log_reg">
              {Cookies.get("login") === "true" ? (
                <ul class="d-flex flex-row align-items-start justify-content-start">
                  <li>
                    <a
                      onClick={() => logout()}
                      style={{ color: "#000", cursor: "pointer" }}
                    >
                      Logout
                    </a>
                  </li>
                  <li>
                    {Cookies.get("login") === "true" ? (
                      <a
                        onClick={() => setBasicModal(true)}
                        style={{ color: "#000", cursor: "pointer" }}
                      >
                        Add Event
                      </a>
                    ) : (
                      <a href="/Login" style={{ color: "#000" }}>
                        Add Event
                      </a>
                    )}
                  </li>
                </ul>
              ) : (
                <ul class="d-flex flex-row align-items-start justify-content-start">
                  <li>
                    <a>
                      {Cookies.get("login") === "true" ? (
                        <a onClick={() => setBasicModal(true)}>Add Event</a>
                      ) : (
                        <a href="/Login">Add Event</a>
                      )}
                    </a>
                  </li>
                  <li>
                    <a href="/Login">Login</a>
                  </li>
                  <li>
                    <a href="/SignUp">Register</a>
                  </li>
                </ul>
              )}
            </div>
            <nav class="menu_nav">
              <ul class="d-flex flex-column align-items-start justify-content-start">
                <li>
                  <a href="/">Home</a>
                </li>
                {Cookies.get("login") === "true" ? (
                  <li>
                    <a href="/Chat">Chat</a>
                  </li>
                ) : null}

                <li>
                  <a href="/Contact">Contact</a>
                </li>
                {Cookies.get("login") === "true" ? (
                  <li>
                    <a href="/Profile">Profile</a>
                  </li>
                ) : null}
              </ul>
            </nav>
          </div>
        </div>

        <div class="home">
          <div class="home_container">
            <div class="home_container_inner d-flex flex-column align-items-center justify-content-center">
              <div class="home_content text-center">
                <div class="home_subtitle">
                  Connect, Play, and Explore with Boongombia
                </div>
                <div class="home_title">
                  <h1>Connect Through Events</h1>
                </div>
                <div class="home_link">
                  <a href="#">Meet, Play, Connect, Explore Together.</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="col-lg-6"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
          }}
        >
          <div
            className="newsletter_form"
            style={{ marginLeft: "5%", marginRight: "5%", display: "flex" }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "5px",
              }}
            >
              <select
                id="dropdown"
                value={selectedOption}
                onChange={handleSelectChange}
                style={{
                  fontStyle: "italic",
                  fontSize: "12px",
                  color: "#337fe2",
                }}
                className="newsletter_input"
              >
                <option value="">Location</option>
                {locations.map((l, index) => (
                  <option value={`${l.location}`}>{l.location}</option>
                ))}
              </select>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "5px",
              }}
            >
              <div
                className="newsletter_input text-start"
                style={{
                  paddingLeft: "0px",
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                }}
              >
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  dateFormat="MM/dd/yyyy"
                  className="newsletter_input2"
                  name="date"
                  isClearable
                  placeholderText="Select a date"
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="col-lg-6"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            // marginBottom: "20px",
          }}
        >
          <div
            className="newsletter_form"
            style={{ marginLeft: "5%", marginRight: "5%" }}
          >
            <input
              type="search"
              className="newsletter_input"
              placeholder="Search..."
              value={searchQuery}
              // style={{ borderRadius: "10px" }}
              onChange={(e) => setSearchQuery(e.target.value)}
            />

            <button
              className="newsletter_button"
              disabled={isdisable}
              onClick={handleSearch}
              id="search"
            >
              Search
            </button>
          </div>
        </div>

        <div
          className="col-lg-6"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginBottom: "20px",
          }}
        >
          <div
            className="newsletter_form"
            style={{ marginLeft: "5%", marginRight: "5%" }}
          >
            <select
              id="dropdown"
              value={categoryOption}
              onChange={handlecategoryChange}
              style={{
                fontStyle: "italic",
                fontSize: "12px",
                color: "#337fe2",
                marginRight: "20px",
              }}
              className="newsletter_input"
            >
              <option value="Date (Increasing Order)">
                Date (Increasing Order)
              </option>
              <option value="Date (Decreasing Order)">
                Date (Decreasing Order)
              </option>
              <option value="Location (Increasing Order)">
                Location (Increasing Order)
              </option>
              <option value="Location (Decreasing Order)">
                Location (Decreasing Order)
              </option>
            </select>
            {/* <input
              type="search"
              className="newsletter_input"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            /> */}
            <button className="newsletter_button" onClick={handleSort}>
              Sort
            </button>
          </div>
        </div>

        <div class="shows" style={{ marginBottom: "100px" }}>
          <div class="container">
            <div class="row shows_row">
              <div class="col-lg-12">
                <div class="section_title_container">
                  <div class="section_subtitle">Events</div>
                  <div class="section_title">
                    <h1>Upcoming Events</h1>
                  </div>
                </div>
                <div class="show_shop trans_200" style={{ margin: "auto" }}>
                  <a
                    href={`/ViewMap?data=${JSON.stringify(data)}`}
                    style={{ color: "#000" }}
                  >
                    View Map
                  </a>
                </div>
                <div class="shows_list_container">
                  <ul
                    class="shows_list"
                    style={{
                      overflowY: "auto",
                      maxHeight: "700px",
                    }}
                  >
                    {data.map((d) => {
                      // Find the status of the current event
                      const requestStatus = requests.find(
                        (request) => request.eventId === d.id
                      )?.status;

                      let buttonText = "Request To Join";
                      let isDisabled = false;

                      if (requestStatus === null) {
                        buttonText = "Requested";
                        isDisabled = true;
                      } else if (requestStatus === "Approved") {
                        buttonText = "Approved";
                        isDisabled = true;
                      } else if (requestStatus === "Rejected") {
                        buttonText = "Rejected";
                        isDisabled = true;
                      }

                      return (
                        <li
                          key={d.id}
                          className="d-flex flex-row align-items-center justify-content-start"
                          style={{ marginBottom: "5px" }}
                        >
                          <div>
                            <div className="show_date">
                              <img
                                src={`${process.env.REACT_APP_URI}/images/${d.image}`}
                                alt=""
                                style={{ width: "78px", height: "72px" }}
                              />
                            </div>
                          </div>
                          <div
                            className="show_info d-flex flex-md-row flex-column align-items-md-center align-items-start justify-content-md-start justify-content-center"
                            style={{
                              marginLeft: "5px",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <div className="show_name">
                              <a href="#">{d.title}</a>
                            </div>
                            <div className="show_location">
                              {d.location}, {d.date}
                            </div>
                          </div>
                          <div className="ml-auto">
                            <div
                              className="show_shop trans_200"
                              onClick={() => handlePostClick(d.title, d.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <a href={`/EventDetail?id=${d.id}`}>View More</a>
                            </div>
                            <div
                              className="show_shop trans_200"
                              style={{
                                marginTop: "5px",
                                color: "#000",
                                cursor: "pointer",
                                padding: "auto",
                              }}
                              onClick={() =>
                                !isDisabled && handleRequestToJoin(d.id)
                              }
                            >
                              {buttonText}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer class="footer">
          <div class="footer_container d-flex flex-xl-row flex-column align-items-start justify-content-center">
            <div class="newsletter_container">
              <img
                src="../assets/logo1.png"
                alt=""
                style={{ maxWidth: "200px", marginBottom: "20px" }}
              />

              <div class="newsletter_title">
                <h2 style={{ color: "#0aae4d" }}>
                  Subscribe to our newsletter
                </h2>
              </div>
              <form action="#" id="newsletter_form" class="newsletter_form">
                <input
                  type="email"
                  class="newsletter_input"
                  placeholder="Your E-mail"
                  required="required"
                  style={{ backgroundColor: "#FFF" }}
                />
                <button class="newsletter_button">Subscribe</button>
              </form>
            </div>
            <div
              class="footer_lists d-flex flex-sm-row  flex-column align-items-start justify-content-center ml-xl-auto"
              style={{ marginBottom: "40px" }}
            >
              <div class="footer_list">
                <div class="footer_list_title" style={{ color: "#0aae4d" }}>
                  Useful Links
                </div>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  {Cookies.get("login") === "true" ? (
                    <li>
                      <a href="/Chat">Chat</a>
                    </li>
                  ) : null}

                  <li>
                    <a href="/Contact">Contact</a>
                  </li>
                  {Cookies.get("login") === "true" ? (
                    <li>
                      <a href="/Requests">Requests</a>
                    </li>
                  ) : null}
                  <li>
                    {Cookies.get("login") === "true" ? (
                      <a
                        onClick={() => setBasicModal(true)}
                        style={{ color: "#fff" }}
                      >
                        Add Event
                      </a>
                    ) : (
                      <a href="/Login">Add Event</a>
                    )}
                  </li>
                </ul>
              </div>

              <div class="footer_list">
                <div class="footer_list_title" style={{ color: "#0aae4d" }}>
                  Boongombia
                </div>
                <ul
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "20px",
                    padding: "0px",
                  }}
                >
                  <li>
                    <a
                      href="https://www.facebook.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebook />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.twitter.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaTwitter />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedin />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="copyright_bar" style={{ marginTop: "50px" }}>
            <span>Copyright &copy; 2024 All rights reserved</span>
          </div>
        </footer>
      </div>

      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
        <MDBModalDialog style={{ borderRadius: 0 }}>
          <MDBModalContent id="card">
            <MDBModalHeader>
              <MDBModalTitle>Add Event</MDBModalTitle>
              <MDBBtn
                style={{ color: "white" }}
                className="btn-close"
                color="none"
                onClick={() => setBasicModal(!basicModal)}
              ></MDBBtn>
            </MDBModalHeader>
            <form
              onSubmit={handleSubmit}
              encType="multipart/form-data"
              id="Coursesform"
            >
              <MDBModalBody>
                <Form.Group className="mb-3">
                  <label htmlFor="imageInput">Select an Image: {"   "}</label>
                  <input
                    type="file"
                    id="imageInput"
                    name="image"
                    accept="image/*"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <input
                    type="text"
                    className="newsletter_input"
                    placeholder="Title"
                    name="title"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <textarea
                    style={{
                      width: "100%",
                      height: "120px",
                      background: "rgba(0, 0, 0, 0.2)",
                      border: "none",
                      outline: "none",
                      paddingLeft: "23px",
                      color: "#000",
                      fontSize: "12px",
                      fontStyle: "italic",
                    }}
                    placeholder="Description"
                    name="description"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <input
                    type="text"
                    className="newsletter_input"
                    placeholder="Location"
                    name="location"
                    autoComplete="off"
                    required
                    value={query}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <div
                    className="newsletter_input text-start"
                    style={{ paddingLeft: "0px" }}
                  >
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      dateFormat="MM/dd/yyyy"
                      className="newsletter_input2"
                      name="date"
                      isClearable
                      placeholderText="Select a date"
                      autoComplete="off"
                    />
                  </div>
                </Form.Group>
                {/* Hide Switch */}
                <Form.Group className="mb-3">
                  <Form.Check
                    type="switch"
                    id="hideSwitch"
                    label="Hide"
                    checked={hide}
                    onChange={() => setHide(!hide)}
                  />
                </Form.Group>
              </MDBModalBody>

              <MDBModalFooter style={{ padding: "40px" }}>
                <button
                  type="submit"
                  className="newsletter_button"
                  style={{ marginTop: "15px", marginRight: "15px" }}
                >
                  {submit ? <MDBSpinner color="info" /> : <span>Submit</span>}
                </button>
              </MDBModalFooter>
            </form>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
};

export default Home;
