import React, { useState } from "react";
import { MDBSpinner } from "mdb-react-ui-kit";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast"; // Importing toast and Toaster

export default function Verification() {
  const [submit, setSubmit] = useState(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const email = urlParams.get("email");
  const code = urlParams.get("auth");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);

    const form = e.target;
    const formData = new FormData(form);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URI}/Verify?email=${email}&auth=${code}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      form.reset();
      const responseData = response.data;
      if (responseData.message === "invalid") {
        setSubmit(false);
        toast.error("INVALID VERIFICATION CODE"); // Use toast for error
      } else if (responseData.message === "verified") {
        setSubmit(false);
        toast.success("EMAIL VERIFIED"); // Use toast for success
        setTimeout(() => {
          window.location.href = `/Login`;
        }, 2000);
      }
    } catch (error) {
      console.error("Error:", error.message);
      setSubmit(false);
      toast.error("An error occurred. Please try again."); // Use toast for error
    }
  };

  return (
    <div>
      {/* Adding Toaster for displaying notifications */}
      <Toaster />
      <div className="super_container" style={{ minHeight: "100vh" }}>
        <div className="contact">
          <div className="container">
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="col-lg-4">
                <div className="contact_form_container">
                  <div className="contact_title" style={{ color: "#000" }}>
                    Verification
                  </div>
                  <form
                    action="#"
                    className="contact_form"
                    id="contact_form"
                    onSubmit={handleSubmit}
                  >
                    <input
                      type="password"
                      className="contact_input"
                      placeholder="Verification Code"
                      required
                      name="code"
                      id="code"
                    />
                    <button className="contact_button" type="submit">
                      {submit ? <MDBSpinner /> : <span>Verify</span>}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
