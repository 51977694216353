import React, { useState, useEffect } from "react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBBtn,
  // MDBBreadcrumb,
  // MDBBreadcrumbItem,
  // MDBProgress,
  // MDBProgressBar,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
  MDBSpinner,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';
import Cookies from "js-cookie";
import Form from "react-bootstrap/Form";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function UserProfile() {
  const [basicModal, setBasicModal] = useState(false);
  const [Modal, setModal] = useState(false);
  const [PasswordModal, setPasswordModal] = useState(false);
  const [SocialModal, setSocialModal] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [results, setResults] = useState([]);
  const [query, setQuery] = useState("");
  const [data, setData] = useState([]);
  const [social, setsocial] = useState([]);
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [email, setemail] = useState("");
  const [phoneno, setphoneno] = useState("");
  const [mobile, setmobile] = useState("");
  const [country, setcountry] = useState("");
  const [city, setcity] = useState("");
  const [address, setaddress] = useState("");
  const [twitter, settwitter] = useState("");
  const [facebook, setfacebook] = useState("");
  const [instagram, setinstagram] = useState("");
  const [load, setLoad] = useState(false);

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };
  function logout() {
    Cookies.remove("login");
    Cookies.remove("email");
    Cookies.remove("token");
    window.location.href = "/";
  }

  const [hide, setHide] = useState(false);


const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);

    const form = e.target;
    const formData = new FormData(form);
    formData.append('hide', hide); // Add hide field to FormData

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URI}/AddEvent?email=${Cookies.get("email")}&latitude=${results[0].geometry.lat}&longitude=${results[0].geometry.lng}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      form.reset();
      setSubmit(false);
      setBasicModal(false);
    } catch (error) {
      console.error("Error:", error.message);
      setSubmit(false);
    }
  };

  

  const handlePassword = async (e) => {
    e.preventDefault();

    if (
      document.getElementById("newpassword").value ===
      document.getElementById("cnewpassword").value
    ) {
      setSubmit(true);

      const form = e.target;
      const formData = new FormData(form);
      console.log(formData);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_URI}/ChangePassword?email=${Cookies.get(
            "email"
          )}&oldpassword=${
            document.getElementById("oldpassword").value
          }&newpassword=${document.getElementById("newpassword").value}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "api-key": process.env.REACT_APP_API_KEY,
            },
          }
        );
        const responseData = response.data;
        if (responseData.message === "updated") {
          form.reset();
          setSubmit(false);
          setPasswordModal(false);
          alert("Password Changed Successfully!");
        } else {
          setSubmit(false);
          alert("Old Password Incorrect!");
        }
      } catch (error) {
        console.error("Error:", error.message);
        setSubmit(false);
      }
    } else {
      alert("Confirm Password do not match!");
    }
  };

  const handleProfile = async (e) => {
    e.preventDefault();
    setSubmit(true);

    try {
      const response = await axios.post(
        `${
          process.env.REACT_APP_URI
        }/EditProfile?firstname=${firstname.trim()}&lastname=${lastname.trim()}
        &email=${email.trim()}
        &phoneno=${phoneno.trim()}
        &mobile=${mobile.trim()}
        &country=${country.trim()}
        &city=${city.trim()}
        &address=${address.trim()}
        `,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      // form.reset();

      setSubmit(false);
      setModal(false);
      alert("Edited Successfully");
      GetProfile();
    } catch (error) {
      console.error("Error:", error.message);
      setSubmit(false);
    }
  };

  const handleSocial = async (e) => {
    e.preventDefault();
    setSubmit(true);

    try {
      const response = await axios.post(
        `${
          process.env.REACT_APP_URI
        }/EditSocial?twitter=${twitter}&instagram=${instagram}
        &email=${email}
        &facebook=${facebook}
        `,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      // form.reset();

      setSubmit(false);
      setSocialModal(false);
      alert("Edited Successfully");
      GetSocialMedia();
    } catch (error) {
      console.error("Error:", error.message);
      setSubmit(false);
    }
  };

  useEffect(() => {
    GetProfile();
    GetSocialMedia();
    console.log(data);
  }, []);

  async function GetProfile() {
    setLoad(true);
    await fetch(
      `${process.env.REACT_APP_URI}/GetProfile?email=${Cookies.get("useremail")}`,
      {
        method: "GET",
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        setData(data.data);
        setfirstname(data.data[0].firstname);
        setlastname(data.data[0].lastname);
        setemail(data.data[0].email);
        setphoneno(data.data[0].phoneno);
        setmobile(data.data[0].mobile);
        setaddress(data.data[0].address);
        setcountry(data.data[0].country);
        setcity(data.data[0].city);
        setLoad(false)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    console.log("Menu Open?", menuOpen); // Debugging line
  };

  async function GetSocialMedia() {
    setLoad(true);
    await fetch(
      `${process.env.REACT_APP_URI}/GetSocialMedia?email=${Cookies.get(
        "email"
      )}`,
      {
        method: "GET",
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        setsocial(data.data);
        settwitter(data.data[0].twitter);
        setfacebook(data.data[0].facebook);
        setinstagram(data.data[0].instagram);
        setLoad(false)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  if(load){
    return(
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <MDBSpinner grow style={{color:'#0aae4d'}}>
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
      </div>
    )
  }

  return (
    <div class="super_container">
      <header class="header">
          <div class="header_content d-flex flex-row align-items-center justify-content-end">
            <div
              class="logo"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                window.location.href = "/";
              }}
            >
              <img
                src="../assets/logo.png"
                style={{ width: "60px", height: "60px" }}
                alt=""
              />
              <a href="/" style={{ marginLeft: "20px" }}>
                Boongombia
              </a>
            </div>
            <div class="log_reg">
              {Cookies.get("login") === "true" ? (
                <ul class="d-flex flex-row align-items-start justify-content-start">
                  <li>
                    <a
                      onClick={() => logout()}
                      style={{ color: "#FFF", cursor: "pointer" }}
                    >
                      Logout
                    </a>
                  </li>
                  <li>
                    {Cookies.get("login") === "true" ? (
                      <a
                        onClick={() => setBasicModal(true)}
                        style={{ color: "#FFF", cursor: "pointer" }}
                      >
                        Add Event
                      </a>
                    ) : (
                      <a href="/Login" style={{ color: "#FFF" }}>
                        Add Event
                      </a>
                    )}
                  </li>
                </ul>
              ) : (
                <ul class="d-flex flex-row align-items-start justify-content-start">
                  <li>
                    {Cookies.get("login") === "true" ? (
                      <a onClick={() => setBasicModal(true)}>Add Event</a>
                    ) : (
                      <a href="/Login">Add Event</a>
                    )}
                  </li>
                  <li>
                    <a href="/Login">Login</a>
                  </li>
                  <li>
                    <a href="/SignUp">Register</a>
                  </li>
                </ul>
              )}
            </div>
            <nav class="main_nav" style={{ marginRight: "200px" }}>
              <ul class="d-flex flex-row align-items-start justify-content-start">
                <li class="active">
                  <a href="/">Home</a>
                </li>
                {Cookies.get("login") === "true" ? (
                  <li>
                    <a href="/Chat">Chat</a>
                  </li>
                ) : null}

                <li>
                  <a href="/Contact">Contact</a>
                </li>
                {Cookies.get("login") === "true" ? (
                  <li>
                    <a href="/Profile">Profile</a>
                  </li>
                ) : null}
              </ul>
            </nav>
            <div class="hamburger ml-auto" onClick={toggleMenu}>
              <div class="d-flex flex-column align-items-end justify-content-between">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </header>

        <div className={`menu ${menuOpen ? "active" : ""}`}>
          <div class="menu_overlay" onClick={()=>{setMenuOpen(false)}}></div>
          <div class="menu_container d-flex flex-column align-items-start justify-content-center">
            <div class="menu_log_reg">
              {Cookies.get("login") === "true" ? (
                <ul class="d-flex flex-row align-items-start justify-content-start">
                  <li>
                    <a
                      onClick={() => logout()}
                      style={{ color: "#000", cursor: "pointer" }}
                    >
                      Logout
                    </a>
                  </li>
                  <li>
                    {Cookies.get("login") === "true" ? (
                      <a
                        onClick={() => setBasicModal(true)}
                        style={{ color: "#000", cursor: "pointer" }}
                      >
                        Add Event
                      </a>
                    ) : (
                      <a href="/Login" style={{ color: "#000" }}>
                        Add Event
                      </a>
                    )}
                  </li>
                </ul>
              ) : (
                <ul class="d-flex flex-row align-items-start justify-content-start">
                  <li>
                    <a>
                      {Cookies.get("login") === "true" ? (
                        <a onClick={() => setBasicModal(true)}>Add Event</a>
                      ) : (
                        <a href="/Login">Add Event</a>
                      )}
                    </a>
                  </li>
                  <li>
                    <a href="/Login">Login</a>
                  </li>
                  <li>
                    <a href="/SignUp">Register</a>
                  </li>
                </ul>
              )}
            </div>
            <nav class="menu_nav">
              <ul class="d-flex flex-column align-items-start justify-content-start">
                <li>
                  <a href="/">Home</a>
                </li>
                {Cookies.get("login") === "true" ? (
                  <li>
                    <a href="/Chat">Chat</a>
                  </li>
                ) : null}

                <li>
                  <a href="/Contact">Contact</a>
                </li>
                {Cookies.get("login") === "true" ? (
                  <li>
                    <a href="/Profile">Profile</a>
                  </li>
                ) : null}
              </ul>
            </nav>
          </div>
        </div>




      <section style={{ backgroundColor: "#eee", marginTop: "100px" }}>
        <MDBContainer className="py-5">
          <MDBRow>
            <MDBCol lg="4">
              <MDBCard className="mb-4">
                <MDBCardBody className="text-center">
                  {data.map((d, index) => (
                    <div>
                      <MDBCardImage
                        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                          d.firstname[0]
                        )}&size=150`}
                        alt="avatar"
                        className="rounded-circle"
                        style={{ width: "150px" }}
                        fluid
                      />
                      <p className="text-muted mb-1">
                        {d.firstname} {d.lastname}
                      </p>
                      <div className="d-flex justify-content-center mb-2">
                        <MDBBtn onClick={()=>handleSubmit()}>
                          Message Now
                        </MDBBtn>
                      </div>
                    </div>
                  ))}
                </MDBCardBody>
              </MDBCard>

              <MDBCard className="mb-4 mb-lg-0">
                <MDBCardBody className="p-0">
                  <MDBListGroup flush className="rounded-3">
                    {social.map((s, index) => (
                      <div>
                        <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                          <MDBIcon
                            fab
                            icon="twitter fa-lg"
                            style={{ color: "#55acee" }}
                          />
                          {s.twitter === null || s.twitter === "" ? (
                          <MDBCardText>--------</MDBCardText>):(<MDBCardText>{s.twitter}</MDBCardText>)}
                        </MDBListGroupItem>
                        <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                          <MDBIcon
                            fab
                            icon="instagram fa-lg"
                            style={{ color: "#ac2bac" }}
                          />
                          {s.instagram === null || s.instagram === "" ? (
                          <MDBCardText>--------</MDBCardText>):(<MDBCardText>{s.instagram}</MDBCardText>)}
                        </MDBListGroupItem>
                        <MDBListGroupItem className="d-flex justify-content-between align-items-center p-3">
                          <MDBIcon
                            fab
                            icon="facebook fa-lg"
                            style={{ color: "#3b5998" }}
                          />
                          {s.facebook === null || s.facebook === "" ? (
                          <MDBCardText>--------</MDBCardText>):(<MDBCardText>{s.facebook}</MDBCardText>)}
                        </MDBListGroupItem>
                        {/* <MDBListGroupItem className="d-flex justify-content-center align-items-center p-3">
                          <MDBBtn
                            outline
                            className="ms-1  d-inline-block"
                            onClick={() => setSocialModal(true)}
                            style={{
                              width: "100px",
                            }}
                          >
                            Edit
                          </MDBBtn>
                        </MDBListGroupItem> */}
                      </div>
                    ))}
                  </MDBListGroup>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol lg="8">
              <MDBCard className="mb-4">
                <MDBCardBody>
                  {data.map((d, index) => (
                    <div>
                      <MDBRow>
                        <MDBCol sm="3">
                          <MDBCardText>First Name</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="9">
                          <MDBCardText className="text-muted">
                            {d.firstname}
                          </MDBCardText>
                        </MDBCol>
                      </MDBRow>
                      <hr />
                      <MDBRow>
                        <MDBCol sm="3">
                          <MDBCardText>Last Name</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="9">
                          <MDBCardText className="text-muted">
                            {d.lastname}
                          </MDBCardText>
                        </MDBCol>
                      </MDBRow>
                      <hr />
                      <MDBRow>
                        <MDBCol sm="3">
                          <MDBCardText>Email</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="9">
                          <MDBCardText className="text-muted">
                            {d.email}
                          </MDBCardText>
                        </MDBCol>
                      </MDBRow>
                      <hr />
                      <MDBRow>
                        <MDBCol sm="3">
                          <MDBCardText>Phone</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="9">
                          {d.phoneno === null || d.phoneno === "" ? (
                            <MDBCardText className="text-muted">
                              --------
                            </MDBCardText>
                          ) : (
                            <MDBCardText className="text-muted">
                              {d.phoneno}
                            </MDBCardText>
                          )}
                        </MDBCol>
                      </MDBRow>
                      <hr />
                      <MDBRow>
                        <MDBCol sm="3">
                          <MDBCardText>Mobile</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="9">
                          <MDBCardText className="text-muted">
                            {d.mobile === null || d.mobile === "" ? (
                              <MDBCardText className="text-muted">
                                --------
                              </MDBCardText>
                            ) : (
                              <MDBCardText className="text-muted">
                                {d.mobile}
                              </MDBCardText>
                            )}
                          </MDBCardText>
                        </MDBCol>
                      </MDBRow>
                      <hr />
                      <MDBRow>
                        <MDBCol sm="3">
                          <MDBCardText>Country</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="9">
                          {d.country === null || d.country === "" ? (
                            <MDBCardText className="text-muted">
                              --------
                            </MDBCardText>
                          ) : (
                            <MDBCardText className="text-muted">
                              {d.country}
                            </MDBCardText>
                          )}
                        </MDBCol>
                      </MDBRow>
                      <hr />
                      <MDBRow>
                        <MDBCol sm="3">
                          <MDBCardText>City</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="9">
                          {d.city === null || d.city === "" ? (
                            <MDBCardText className="text-muted">
                              --------
                            </MDBCardText>
                          ) : (
                            <MDBCardText className="text-muted">
                              {d.city}
                            </MDBCardText>
                          )}
                        </MDBCol>
                      </MDBRow>
                      <hr />
                      <MDBRow>
                        <MDBCol sm="3">
                          <MDBCardText>Address</MDBCardText>
                        </MDBCol>
                        <MDBCol sm="9">
                          {d.address === null || d.address === "" ? (
                            <MDBCardText className="text-muted">
                              --------
                            </MDBCardText>
                          ) : (
                            <MDBCardText className="text-muted">
                              {d.address}
                            </MDBCardText>
                          )}
                        </MDBCol>
                      </MDBRow>
                    </div>
                  ))}
                  <hr />
                  {/* <MDBBtn
                    outline
                    className="ms-1"
                    onClick={() => setModal(true)}
                    style={{ width: "100px" }}
                  >
                    Edit
                  </MDBBtn> */}
                </MDBCardBody>
              </MDBCard>

              {/* <MDBRow>
                <MDBCol md="6">
                  <MDBCard className="mb-4 mb-md-0">
                    <MDBCardBody>
                      <MDBCardText className="mb-4">
                        <span className="text-primary font-italic me-1">
                          assigment
                        </span>{" "}
                        Project Status
                      </MDBCardText>
                      <MDBCardText
                        className="mb-1"
                        style={{ fontSize: ".77rem" }}
                      >
                        Web Design
                      </MDBCardText>
                      <MDBProgress className="rounded">
                        <MDBProgressBar
                          width={80}
                          valuemin={0}
                          valuemax={100}
                        />
                      </MDBProgress>

                      <MDBCardText
                        className="mt-4 mb-1"
                        style={{ fontSize: ".77rem" }}
                      >
                        Website Markup
                      </MDBCardText>
                      <MDBProgress className="rounded">
                        <MDBProgressBar
                          width={72}
                          valuemin={0}
                          valuemax={100}
                        />
                      </MDBProgress>

                      <MDBCardText
                        className="mt-4 mb-1"
                        style={{ fontSize: ".77rem" }}
                      >
                        One Page
                      </MDBCardText>
                      <MDBProgress className="rounded">
                        <MDBProgressBar
                          width={89}
                          valuemin={0}
                          valuemax={100}
                        />
                      </MDBProgress>

                      <MDBCardText
                        className="mt-4 mb-1"
                        style={{ fontSize: ".77rem" }}
                      >
                        Mobile Template
                      </MDBCardText>
                      <MDBProgress className="rounded">
                        <MDBProgressBar
                          width={55}
                          valuemin={0}
                          valuemax={100}
                        />
                      </MDBProgress>

                      <MDBCardText
                        className="mt-4 mb-1"
                        style={{ fontSize: ".77rem" }}
                      >
                        Backend API
                      </MDBCardText>
                      <MDBProgress className="rounded">
                        <MDBProgressBar
                          width={66}
                          valuemin={0}
                          valuemax={100}
                        />
                      </MDBProgress>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>

                <MDBCol md="6">
                  <MDBCard className="mb-4 mb-md-0">
                    <MDBCardBody>
                      <MDBCardText className="mb-4">
                        <span className="text-primary font-italic me-1">
                          assigment
                        </span>{" "}
                        Project Status
                      </MDBCardText>
                      <MDBCardText
                        className="mb-1"
                        style={{ fontSize: ".77rem" }}
                      >
                        Web Design
                      </MDBCardText>
                      <MDBProgress className="rounded">
                        <MDBProgressBar
                          width={80}
                          valuemin={0}
                          valuemax={100}
                        />
                      </MDBProgress>

                      <MDBCardText
                        className="mt-4 mb-1"
                        style={{ fontSize: ".77rem" }}
                      >
                        Website Markup
                      </MDBCardText>
                      <MDBProgress className="rounded">
                        <MDBProgressBar
                          width={72}
                          valuemin={0}
                          valuemax={100}
                        />
                      </MDBProgress>

                      <MDBCardText
                        className="mt-4 mb-1"
                        style={{ fontSize: ".77rem" }}
                      >
                        One Page
                      </MDBCardText>
                      <MDBProgress className="rounded">
                        <MDBProgressBar
                          width={89}
                          valuemin={0}
                          valuemax={100}
                        />
                      </MDBProgress>

                      <MDBCardText
                        className="mt-4 mb-1"
                        style={{ fontSize: ".77rem" }}
                      >
                        Mobile Template
                      </MDBCardText>
                      <MDBProgress className="rounded">
                        <MDBProgressBar
                          width={55}
                          valuemin={0}
                          valuemax={100}
                        />
                      </MDBProgress>

                      <MDBCardText
                        className="mt-4 mb-1"
                        style={{ fontSize: ".77rem" }}
                      >
                        Backend API
                      </MDBCardText>
                      <MDBProgress className="rounded">
                        <MDBProgressBar
                          width={66}
                          valuemin={0}
                          valuemax={100}
                        />
                      </MDBProgress>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow> */}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <footer class="footer">
          <div class="footer_container d-flex flex-xl-row flex-column align-items-start justify-content-center">
            <div class="newsletter_container" >
              <img src="../assets/logo1.png" alt="" style={{ maxWidth: '200px', marginBottom: '20px' }} />

              <div class="newsletter_title">
                <h2 style={{ color: '#0aae4d' }}>Subscribe to our newsletter</h2>
              </div>
              <form action="#" id="newsletter_form" class="newsletter_form" >
                <input
                  type="email"
                  class="newsletter_input"
                  placeholder="Your E-mail"
                  required="required"
                  style={{ backgroundColor: "#FFF" }}
                />
                <button class="newsletter_button">Subscribe</button>
              </form>
            </div>
            <div
              class="footer_lists d-flex flex-sm-row  flex-column align-items-start justify-content-center ml-xl-auto"
              style={{ marginBottom: "40px" }}
            >
              <div class="footer_list">
                <div class="footer_list_title" style={{ color: '#0aae4d' }}>Useful Links</div>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  {Cookies.get("login") === "true" ? (
                <li>
                  <a href="/Chat">Chat</a>
                </li>):null
                }

                  <li>
                    <a href="/Contact">Contact</a>
                  </li>
                  <li>
                  {Cookies.get("login") === "true" ? (
                      <a onClick={() => setBasicModal(true)} style={{color:'#fff'}}>Add Event</a>
                    ) : (
                      <a href="/Login">Add Event</a>
                    )}
                  </li>
                </ul>
              </div>

              <div class="footer_list">
                <div class="footer_list_title" style={{ color: '#0aae4d' }}>Boongombia</div>
                <ul style={{ display: 'flex', justifyContent: 'center', gap: '20px', padding: '0px' }}>
                  <li>
                    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                      <FaFacebook />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                      <FaTwitter />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                      <FaLinkedin />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                      <FaInstagram />
                    </a>
                  </li>
                </ul>
              </div>

            </div>
          </div>
          <div class="copyright_bar" style={{marginTop:'50px'}}>
            <span>Copyright &copy; 2024 All rights reserved</span>
          </div>
        </footer>

        <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
      <MDBModalDialog style={{ borderRadius: 0 }}>
        <MDBModalContent id="card">
          <MDBModalHeader>
            <MDBModalTitle>Add Event</MDBModalTitle>
            <MDBBtn
              style={{ color: "white" }}
              className="btn-close"
              color="none"
              onClick={() => setBasicModal(!basicModal)}
            ></MDBBtn>
          </MDBModalHeader>
          <form onSubmit={handleSubmit} encType="multipart/form-data" id="Coursesform">
            <MDBModalBody>
              <Form.Group className="mb-3">
                <label htmlFor="imageInput">Select an Image: {"   "}</label>
                <input
                  type="file"
                  id="imageInput"
                  name="image"
                  accept="image/*"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <input
                  type="text"
                  className="newsletter_input"
                  placeholder="Title"
                  name="title"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <textarea
                  style={{
                    width: "100%",
                    height: "120px",
                    background: "rgba(0, 0, 0, 0.2)",
                    border: "none",
                    outline: "none",
                    paddingLeft: "23px",
                    color: "#000",
                    fontSize: "12px",
                    fontStyle: "italic",
                  }}
                  placeholder="Description"
                  name="description"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <input
                  type="text"
                  className="newsletter_input"
                  placeholder="Location"
                  name="location"
                  autoComplete="off"
                  required
                  value={query}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <div
                  className="newsletter_input text-start"
                  style={{ paddingLeft: "0px" }}
                >
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="MM/dd/yyyy"
                    className="newsletter_input2"
                    name="date"
                    isClearable
                    placeholderText="Select a date"
                    autoComplete="off"
                  />
                </div>
              </Form.Group>
              {/* Hide Switch */}
              <Form.Group className="mb-3">
                <Form.Check
                  type="switch"
                  id="hideSwitch"
                  label="Hide"
                  checked={hide}
                  onChange={() => setHide(!hide)}
                />
              </Form.Group>
            </MDBModalBody>

            <MDBModalFooter style={{ padding: "40px" }}>
              <button
                type="submit"
                className="newsletter_button"
                style={{ marginTop: "15px", marginRight: "15px" }}
              >
                {submit ? <MDBSpinner color="info" /> : <span>Submit</span>}
              </button>
            </MDBModalFooter>
          </form>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>

      <MDBModal show={Modal} setShow={setModal} tabIndex="-1">
        <MDBModalDialog style={{ borderRadius: 0 }}>
          <MDBModalContent id="card">
            <MDBModalHeader>
              <MDBModalTitle>Edit Profile</MDBModalTitle>
              <MDBBtn
                style={{ color: "white" }}
                className="btn-close"
                color="none"
                onClick={() => setModal(!Modal)}
              ></MDBBtn>
            </MDBModalHeader>
            <form
              onSubmit={handleProfile}
              encType="multipart/form-data"
              id="Coursesform"
            >
              <MDBModalBody>
                <Form.Group className="mb-3">
                  <input
                    type="text"
                    className="newsletter_input"
                    placeholder="First Name"
                    name="firstname"
                    value={firstname}
                    onChange={(e) => setfirstname(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <input
                    type="text"
                    className="newsletter_input"
                    placeholder="Last Name"
                    name="lastname"
                    value={lastname}
                    onChange={(e) => setlastname(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <input
                    type="text"
                    className="newsletter_input"
                    placeholder="Email"
                    name="email"
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                    required
                    disabled
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <input
                    type="text"
                    className="newsletter_input"
                    placeholder="Phone"
                    name="phoneno"
                    value={phoneno}
                    onChange={(e) => setphoneno(e.target.value)}
                    // required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <input
                    type="text"
                    className="newsletter_input"
                    placeholder="Mobile"
                    name="mobile"
                    value={mobile}
                    onChange={(e) => setmobile(e.target.value)}
                    // required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <input
                    type="text"
                    className="newsletter_input"
                    placeholder="Country"
                    name="country"
                    value={country}
                    onChange={(e) => setcountry(e.target.value)}
                    // required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <input
                    type="text"
                    className="newsletter_input"
                    placeholder="City"
                    name="city"
                    value={city}
                    onChange={(e) => setcity(e.target.value)}
                    // required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <input
                    type="text"
                    className="newsletter_input"
                    placeholder="Address"
                    name="address"
                    value={address}
                    onChange={(e) => setaddress(e.target.value)}
                    // required
                  />
                </Form.Group>
              </MDBModalBody>

              <MDBModalFooter style={{ padding: "40px" }}>
                <button
                  type="submit"
                  className="newsletter_button"
                  style={{ marginTop: "15px", marginRight: "15px" }}
                >
                  {submit ? <MDBSpinner color="info" /> : <span>Edit</span>}
                </button>
              </MDBModalFooter>
            </form>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal show={PasswordModal} setShow={setPasswordModal} tabIndex="-1">
        <MDBModalDialog style={{ borderRadius: 0 }}>
          <MDBModalContent id="card">
            <MDBModalHeader>
              <MDBModalTitle>Change Password</MDBModalTitle>
              <MDBBtn
                style={{ color: "white" }}
                className="btn-close"
                color="none"
                onClick={() => setPasswordModal(!PasswordModal)}
              ></MDBBtn>
            </MDBModalHeader>
            <form
              onSubmit={handlePassword}
              encType="multipart/form-data"
              id="Coursesform"
            >
              <MDBModalBody>
                <Form.Group className="mb-3">
                  <input
                    type="password"
                    className="newsletter_input"
                    placeholder="Old Password"
                    name="oldpassword"
                    id="oldpassword"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <input
                    type="password"
                    className="newsletter_input"
                    placeholder="New Password"
                    name="newpassword"
                    id="newpassword"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <input
                    type="password"
                    className="newsletter_input"
                    placeholder="Confirm New Password"
                    name="cnewpassword"
                    id="cnewpassword"
                    required
                  />
                </Form.Group>
              </MDBModalBody>

              <MDBModalFooter style={{ padding: "40px" }}>
                <button
                  type="submit"
                  className="newsletter_button"
                  style={{ marginTop: "15px", marginRight: "15px" }}
                >
                  {submit ? <MDBSpinner color="info" /> : <span>Change</span>}
                </button>
              </MDBModalFooter>
            </form>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>


      <MDBModal show={SocialModal} setShow={setSocialModal} tabIndex="-1">
        <MDBModalDialog style={{ borderRadius: 0 }}>
          <MDBModalContent id="card">
            <MDBModalHeader>
              <MDBModalTitle>Socail Media Links</MDBModalTitle>
              <MDBBtn
                style={{ color: "white" }}
                className="btn-close"
                color="none"
                onClick={() => setSocialModal(!SocialModal)}
              ></MDBBtn>
            </MDBModalHeader>
            <form
              onSubmit={handleSocial}
              encType="multipart/form-data"
              id="Coursesform"
            >
              <MDBModalBody>
                <Form.Group className="mb-3">
                  <input
                    type="text"
                    className="newsletter_input"
                    placeholder="Twitter"
                    name="twitter"
                    id="twitter"
                    value={twitter}
                    onChange={(e) => settwitter(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <input
                    type="text"
                    className="newsletter_input"
                    placeholder="Instagram"
                    name="instagram"
                    id="instagram"
                    value={instagram}
                    onChange={(e) => setinstagram(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <input
                    type="text"
                    className="newsletter_input"
                    placeholder="Facebook"
                    name="facebook"
                    id="facebook"
                    value={facebook}
                    onChange={(e) => setfacebook(e.target.value)}
                  />
                </Form.Group>
              </MDBModalBody>

              <MDBModalFooter style={{ padding: "40px" }}>
                <button
                  type="submit"
                  className="newsletter_button"
                  style={{ marginTop: "15px", marginRight: "15px" }}
                >
                  {submit ? <MDBSpinner color="info" /> : <span>Edit</span>}
                </button>
              </MDBModalFooter>
            </form>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
}
