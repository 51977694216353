import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBSpinner,
    MDBSwitch,
  } from "mdb-react-ui-kit";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';
  import axios from "axios";
  import Form from "react-bootstrap/Form";

export default function Requests() {
  const [requests, setRequests] = useState([]); // State to store fetched data
  const email = Cookies.get('email'); // Retrieve email from cookies

  useEffect(() => {
    fetchRequests();
  }, [email]);

  // Function to fetch requests
  const fetchRequests = () => {
    setLoad(true);
    fetch(`${process.env.REACT_APP_URI}/GetRequests?email=${email}`)
      .then(response => response.json())
      .then(data => {
        setRequests(data.data); // Update state with fetched data
    setLoad(false);

      })
      .catch(error => console.error('Error fetching requests:', error));

  };

  // Function to handle approve or reject actions
  const handleAction = (id, action) => {
    fetch(`${process.env.REACT_APP_URI}/UpdateRequestStatus?requestId=${id}&status=${action}`, {
      method: 'Put',
    })
      .then(response => response.json())
      .then(() => {
        // Refetch requests after updating status
        fetchRequests();
      })
      .catch(error => console.error('Error updating request status:', error));
  };

  const [basicModal, setBasicModal] = useState(false);

  const [query, setQuery] = useState("");


  const [hide, setHide] = useState(false);


const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);

    const form = e.target;
    const formData = new FormData(form);
    formData.append('hide', hide); // Add hide field to FormData

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URI}/AddEvent?email=${Cookies.get("email")}&latitude=${results[0].geometry.lat}&longitude=${results[0].geometry.lng}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      form.reset();
      setSubmit(false);
      setBasicModal(false);
    } catch (error) {
      console.error("Error:", error.message);
      setSubmit(false);
    }
  };

  
  const [load, setLoad] = useState(false);


  const [results, setResults] = useState([]);


  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const response = await axios.get(
          `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
            query
          )}&key=7c3db7d1298a49ac8e3962fbf93dad32`
        );

        setResults(response.data.results);
        console.log(response.data.results);
      } catch (error) {
        console.error("Error fetching location data:", error);
      }
    };

    // Fetch location data only if the query is not empty
    if (query.trim() !== "") {
      fetchLocationData();
    }
  }, [query]);


  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    console.log("Menu Open?", menuOpen); // Debugging line
  };


  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion.formatted);
    // You can perform additional actions when a suggestion is clicked
  };


  const [submit, setSubmit] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  function logout() {
    Cookies.remove("login");
    Cookies.remove("email");
    Cookies.remove("token");
    window.location.href = "/";
  }

  if (load) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <MDBSpinner grow style={{ color: '#0aae4d' }}>
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
      </div>
    )
  }

  return (
    <div class="super_container">
        <header class="header">
          <div class="header_content d-flex flex-row align-items-center justify-content-end">
            <div
              class="logo"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                window.location.href = "/";
              }}
            >
              <img
                src="../assets/logo.png"
                style={{ width: "60px", height: "60px" }}
                alt=""
              />
              <a href="/" style={{ marginLeft: "20px" }}>
                Boongombia
              </a>
            </div>
            <div class="log_reg">
              {Cookies.get("login") === "true" ? (
                <ul class="d-flex flex-row align-items-start justify-content-start">
                  <li>
                    <a
                      onClick={() => logout()}
                      style={{ color: "#FFF", cursor: "pointer" }}
                    >
                      Logout
                    </a>
                  </li>
                  <li>
                    {Cookies.get("login") === "true" ? (
                      <a
                        onClick={() => setBasicModal(true)}
                        style={{ color: "#FFF", cursor: "pointer" }}
                      >
                        Add Event
                      </a>
                    ) : (
                      <a href="/Login" style={{ color: "#FFF" }}>
                        Add Event
                      </a>
                    )}
                  </li>
                </ul>
              ) : (
                <ul class="d-flex flex-row align-items-start justify-content-start">
                  <li>
                    {Cookies.get("login") === "true" ? (
                      <a onClick={() => setBasicModal(true)}>Add Event</a>
                    ) : (
                      <a href="/Login">Add Event</a>
                    )}
                  </li>
                  <li>
                    <a href="/Login">Login</a>
                  </li>
                  <li>
                    <a href="/SignUp">Register</a>
                  </li>
                </ul>
              )}
            </div>
            <nav class="main_nav" style={{ marginRight: "200px" }}>
              <ul class="d-flex flex-row align-items-start justify-content-start">
                <li class="active">
                  <a href="/">Home</a>
                </li>
                {Cookies.get("login") === "true" ? (
                  <li>
                    <a href="/Chat">Chat</a>
                  </li>
                ) : null}

                <li>
                  <a href="/Contact">Contact</a>
                </li>
                {Cookies.get("login") === "true" ? (
                  <li>
                    <a href="/Profile">Profile</a>
                  </li>
                ) : null}
              </ul>
            </nav>
            <div class="hamburger ml-auto" onClick={toggleMenu}>
              <div class="d-flex flex-column align-items-end justify-content-between">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </header>

        <div className={`menu ${menuOpen ? "active" : ""}`}>
          <div class="menu_overlay" onClick={()=>{setMenuOpen(false)}}></div>
          <div class="menu_container d-flex flex-column align-items-start justify-content-center">
            <div class="menu_log_reg">
              {Cookies.get("login") === "true" ? (
                <ul class="d-flex flex-row align-items-start justify-content-start">
                  <li>
                    <a
                      onClick={() => logout()}
                      style={{ color: "#000", cursor: "pointer" }}
                    >
                      Logout
                    </a>
                  </li>
                  <li>
                    {Cookies.get("login") === "true" ? (
                      <a
                        onClick={() => setBasicModal(true)}
                        style={{ color: "#000", cursor: "pointer" }}
                      >
                        Add Event
                      </a>
                    ) : (
                      <a href="/Login" style={{ color: "#000" }}>
                        Add Event
                      </a>
                    )}
                  </li>
                </ul>
              ) : (
                <ul class="d-flex flex-row align-items-start justify-content-start">
                  <li>
                    <a>
                      {Cookies.get("login") === "true" ? (
                        <a onClick={() => setBasicModal(true)}>Add Event</a>
                      ) : (
                        <a href="/Login">Add Event</a>
                      )}
                    </a>
                  </li>
                  <li>
                    <a href="/Login">Login</a>
                  </li>
                  <li>
                    <a href="/SignUp">Register</a>
                  </li>
                </ul>
              )}
            </div>
            <nav class="menu_nav">
              <ul class="d-flex flex-column align-items-start justify-content-start">
                <li>
                  <a href="/">Home</a>
                </li>
                {Cookies.get("login") === "true" ? (
                  <li>
                    <a href="/Chat">Chat</a>
                  </li>
                ) : null}

                <li>
                  <a href="/Contact">Contact</a>
                </li>
                {Cookies.get("login") === "true" ? (
                  <li>
                    <a href="/Profile">Profile</a>
                  </li>
                ) : null}
              </ul>
            </nav>
          </div>
        </div>

    <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'150px',marginBottom:'50px'}}>
      <Col lg={10}>
        <div style={{ padding: '20px', display: 'grid', gap: '20px', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))' }}>
          {requests.map((request) => (
            <div 
              key={request.id} 
              style={{ 
                border: '1px solid #ddd', 
                borderRadius: '8px', 
                padding: '20px', 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'flex-start', 
                backgroundColor: '#fff', 
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
              }}
            >
              {/* Event Image */}
              <img 
                src={`${process.env.REACT_APP_URI}/images/${request.image}`} 
                alt="Event" 
                style={{ width: '100%', height: 'auto', borderRadius: '5px', marginBottom: '10px' }} 
              />

              {/* Title */}
              <h3 style={{ margin: '0 0 10px 0', textAlign: 'center' }}>{request.title}</h3>

              {/* User Info Section */}
              <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: '10px' }}>
                {/* User Image */}
                <img 
                  src={`${process.env.REACT_APP_URI}/images/${request.userimage}`} 
                  alt="User" 
                  style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px' }} 
                />
                {/* User Names */}
                <div>
                  <p style={{ margin: '0', fontWeight: 'bold' }}>{request.firstname}</p>
                  <p style={{ margin: '0', fontSize: 'small', color: '#555' }}>{request.lastname}</p>
                </div>
              </div>

              {/* Conditional Rendering for Status or Buttons */}
              {request.status === null ? (
                <div style={{ display: 'flex', gap: '10px', width: '100%', justifyContent: 'space-between' }}>
                  <button 
                    onClick={() => handleAction(request.id, 'Approved')} 
                    style={{ 
                      backgroundColor: 'green', 
                      color: 'white', 
                      padding: '10px 20px', 
                      border: 'none', 
                      borderRadius: '5px', 
                      cursor: 'pointer', 
                      flex: '1' 
                    }}
                  >
                    Approve
                  </button>
                  <button 
                    onClick={() => handleAction(request.id, 'Rejected')} 
                    style={{ 
                      backgroundColor: 'red', 
                      color: 'white', 
                      padding: '10px 20px', 
                      border: 'none', 
                      borderRadius: '5px', 
                      cursor: 'pointer', 
                      flex: '1' 
                    }}
                  >
                    Reject
                  </button>
                </div>
              ) : request.status === 'Approved' ? (
                <div style={{ padding: '10px', backgroundColor: '#d4edda', color: '#155724', borderRadius: '5px', width: '100%', textAlign: 'center' }}>
                  Approved
                </div>
              ) : (
                <div style={{ padding: '10px', backgroundColor: '#f8d7da', color: '#721c24', borderRadius: '5px', width: '100%', textAlign: 'center' }}>
                  Rejected
                </div>
              )}
            </div>
          ))}
        </div>
      </Col>
    </div>      












    <footer class="footer">
          <div class="footer_container d-flex flex-xl-row flex-column align-items-start justify-content-center">
            <div class="newsletter_container" >
              <img src="../assets/logo1.png" alt="" style={{ maxWidth: '200px', marginBottom: '20px' }} />

              <div class="newsletter_title">
                <h2 style={{ color: '#0aae4d' }}>Subscribe to our newsletter</h2>
              </div>
              <form action="#" id="newsletter_form" class="newsletter_form" >
                <input
                  type="email"
                  class="newsletter_input"
                  placeholder="Your E-mail"
                  required="required"
                  style={{ backgroundColor: "#FFF" }}
                />
                <button class="newsletter_button">Subscribe</button>
              </form>
            </div>
            <div
              class="footer_lists d-flex flex-sm-row  flex-column align-items-start justify-content-center ml-xl-auto"
              style={{ marginBottom: "40px" }}
            >
              <div class="footer_list">
                <div class="footer_list_title" style={{ color: '#0aae4d' }}>Useful Links</div>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  {Cookies.get("login") === "true" ? (
                <li>
                  <a href="/Chat">Chat</a>
                </li>):null
                }

                  <li>
                    <a href="/Contact">Contact</a>
                  </li>
                  {Cookies.get("login") === "true" ? (
                <li>
                  <a href="/Requests">Requests</a>
                </li>):null
                }
                  <li>
                  {Cookies.get("login") === "true" ? (
                      <a onClick={() => setBasicModal(true)} style={{color:'#fff'}}>Add Event</a>
                    ) : (
                      <a href="/Login">Add Event</a>
                    )}
                  </li>
                </ul>
              </div>

              <div class="footer_list">
                <div class="footer_list_title" style={{ color: '#0aae4d' }}>Boongombia</div>
                <ul style={{ display: 'flex', justifyContent: 'center', gap: '20px', padding: '0px' }}>
                  <li>
                    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                      <FaFacebook />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                      <FaTwitter />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                      <FaLinkedin />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                      <FaInstagram />
                    </a>
                  </li>
                </ul>
              </div>

            </div>
          </div>
          <div class="copyright_bar" style={{marginTop:'50px'}}>
            <span>Copyright &copy; 2024 All rights reserved</span>
          </div>
        </footer>


        <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
      <MDBModalDialog style={{ borderRadius: 0 }}>
        <MDBModalContent id="card">
          <MDBModalHeader>
            <MDBModalTitle>Add Event</MDBModalTitle>
            <MDBBtn
              style={{ color: "white" }}
              className="btn-close"
              color="none"
              onClick={() => setBasicModal(!basicModal)}
            ></MDBBtn>
          </MDBModalHeader>
          <form onSubmit={handleSubmit} encType="multipart/form-data" id="Coursesform">
            <MDBModalBody>
              <Form.Group className="mb-3">
                <label htmlFor="imageInput">Select an Image: {"   "}</label>
                <input
                  type="file"
                  id="imageInput"
                  name="image"
                  accept="image/*"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <input
                  type="text"
                  className="newsletter_input"
                  placeholder="Title"
                  name="title"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <textarea
                  style={{
                    width: "100%",
                    height: "120px",
                    background: "rgba(0, 0, 0, 0.2)",
                    border: "none",
                    outline: "none",
                    paddingLeft: "23px",
                    color: "#000",
                    fontSize: "12px",
                    fontStyle: "italic",
                  }}
                  placeholder="Description"
                  name="description"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <input
                  type="text"
                  className="newsletter_input"
                  placeholder="Location"
                  name="location"
                  autoComplete="off"
                  required
                  value={query}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <div
                  className="newsletter_input text-start"
                  style={{ paddingLeft: "0px" }}
                >
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="MM/dd/yyyy"
                    className="newsletter_input2"
                    name="date"
                    isClearable
                    placeholderText="Select a date"
                    autoComplete="off"
                  />
                </div>
              </Form.Group>
              {/* Hide Switch */}
              <Form.Group className="mb-3">
                <Form.Check
                  type="switch"
                  id="hideSwitch"
                  label="Hide"
                  checked={hide}
                  onChange={() => setHide(!hide)}
                />
              </Form.Group>
            </MDBModalBody>

            <MDBModalFooter style={{ padding: "40px" }}>
              <button
                type="submit"
                className="newsletter_button"
                style={{ marginTop: "15px", marginRight: "15px" }}
              >
                {submit ? <MDBSpinner color="info" /> : <span>Submit</span>}
              </button>
            </MDBModalFooter>
          </form>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>


    </div>
  );
}
