import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  Search,
  ConversationList,
  Conversation,
  Avatar,
  Sidebar,
  ChatContainer,
  ConversationHeader,
  VoiceCallButton,
  VideoCallButton,
  EllipsisButton,
  MessageList,
  TypingIndicator,
  MessageSeparator,
  Message,
  MessageInput,
} from "@chatscope/chat-ui-kit-react";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";

const Chat = () => {
  const [messageInputValue, setMessageInputValue] = useState("");
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [chatid, setChatid] = useState(0);
  const [chat, setChat] = useState([]);
  const [filterchat, setfilterchat] = useState([]);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState([]);
  // const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    const searchInput = document.getElementById('search');
  
    if (searchInput) {
      const searchTerm = searchInput.value.toLowerCase();
  
      const filteredChats = chat.filter((chatItem) => {
        const firstNameLowerCase =
          (chatItem.firstname && typeof chatItem.firstname === 'string'
            ? chatItem.firstname
            : ''
          ).toLowerCase();
        const lastNameLowerCase =
          (chatItem.lastname && typeof chatItem.lastname === 'string'
            ? chatItem.lastname
            : ''
          ).toLowerCase();
  
        return (
          firstNameLowerCase.includes(searchTerm) || lastNameLowerCase.includes(searchTerm)
        );
      });
  
      setfilterchat(filteredChats);
    } else {
      console.error("Element with ID 'search' not found");
    }
  };
  

  useEffect(() => {
    GetChat();
    // GetMessages();
  }, []);

  useEffect(() => {
    GetMessages();
    // Fetch messages every one second
    const intervalId = setInterval(GetMessages, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [chatid]);

  async function GetChat() {
    await fetch(
      `${process.env.REACT_APP_URI}/GetChat?email=${Cookies.get("email")}`,
      {
        method: "GET",
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        setChat(data.data);
        setfilterchat(data.data);
        setChatid(data.data[0].Id);
        console.log(data.data[0].Id);
        setName(data.data[0].firstname + " " + data.data[0].lastname);
        console.log(data.data);
        // GetMessages();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  async function GetMessages() {
    await fetch(`${process.env.REACT_APP_URI}/GetMessages?chatid=${chatid}`, {
      method: "GET",
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        setMessages(data.data);

        let lastDate = null;

        const messagesWithDateFlag = data.data.map((item, index) => {
          const messageDate = new Date(item.CreatedAt).toLocaleDateString();
          let isNewDate = false;

          if (lastDate !== messageDate) {
            isNewDate = true;
            lastDate = messageDate;
          }

          return {
            ...item,
            isNewDate: isNewDate,
          };
        });
        setMessage(messagesWithDateFlag);
        console.log(messagesWithDateFlag);
        // setDate(data.data[0].createdAt);
        console.log(data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(e.target.value!=""){
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_URI}/AddMessage?user=${Cookies.get(
            "email"
          )}&message=${messageInputValue}&chatid=${chatid}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "api-key": process.env.REACT_APP_API_KEY,
            },
          }
        );
        GetMessages();
        setMessageInputValue("");
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };
  return (
    <>
      <div
        onClick={(e) => {
          handleSubmit(e);
        }}
        className=""
        style={{
          zIndex: "9999",
          backgroundColor: "transparent",
          position: "absolute",
          bottom: "0",
          right: "0",
          width: "45px",
          height: "45px",
        }}
      ></div>
      <div
        style={{
          height: "100vh",
          position: "relative",
        }}
      >
        <MainContainer responsive>
          <Sidebar position="left" scrollable={false}>
            <input placeholder="Search..." style={{backgroundColor:'#c6e3fa',borderRadius:'5px',border:'none',height:'50px',padding:'10px'}} id="search" onChange={(e)=>{handleSearch(e)}}/>
            <ConversationList>
              {filterchat.map((item, index) => (
                <Conversation
                  key={index}
                  name={item.firstname + " " + item.lastname}
                  style={{ borderBottom: "1px solid #ccc" }}
                  unreadDot
                  onClick={() => {
                    setName(item.firstname + " " + item.lastname);
                    setChatid(item.Id);
                  }}
                >
                  <Avatar
                    src={`https://ui-avatars.com/api/?name=${item.firstname[0]}&size=150`}
                    name={item.firstname}
                  />
                </Conversation>
              ))}
            </ConversationList>
          </Sidebar>

          <ChatContainer>
            <ConversationHeader>
              <ConversationHeader.Back />
              <Avatar
                src={`https://ui-avatars.com/api/?name=${name[0]}&size=150`}
                name={`${name}`}
              />
              <ConversationHeader.Content
                userName={`${name}`}
              />
            </ConversationHeader>
            <MessageList
            >
              {message.map((item, index) => (
                <div key={index}>
                  {!item.isNewDate ? null : (
                    <div>
                      <MessageSeparator
                        content={`${new Date(
                          item.CreatedAt
                        ).toLocaleDateString()}`}
                      />
                    </div>
                  )}
                  {Cookies.get("email") === item.User ? (
                    <Message
                      key={`outgoing-${index}`}
                      model={{
                        message: `${item.Message}`,
                        sentTime: "15 mins ago",
                        sender: "Patrik",
                        direction: "outgoing",
                        position: "single",
                      }}
                      avatarSpacer
                    />
                  ) : (
                    <Message
                      model={{
                        message: `${item.Message}`,
                        sentTime: "15 mins ago",
                        sender: "Zoe",
                        direction: "incoming",
                        position: "last",
                      }}
                    >
                      <Avatar
                        src={`https://ui-avatars.com/api/?name=${name[0]}&size=150`}
                        name="Zoe"
                      />
                    </Message>
                  )}
                </div>
              ))}
            </MessageList>
            <MessageInput
              style={{ textAlign: "left" }}
              placeholder="Type message here"
              value={messageInputValue}
              onChange={(val) => setMessageInputValue(val)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit(e); 
                }
              }}
            />
          </ChatContainer>
        </MainContainer>
      </div>
    </>
  );
};

export default Chat;
