import "./App.css";
import { Switch, Route } from "react-router-dom";
import Home from "./Components/Home";
import Login from "./Components/Login";
import SignUp from "./Components/SignUp";
import Verification from "./Components/Verification";
import EventDetail from "./Components/EventDetail";
import Profile from "./Components/Profile";
import UserProfile from "./Components/UserProfile";
import Chat from "./Components/Chat";
import ViewMap from "./Components/ViewMap";
import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import ContactUs from "./Components/ContactUs";
import Requests from "./Components/Requests";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.gtag('config', 'G-H51BX5EC8W', {
      page_path: location.pathname + location.search,
    });
  }, [location]);
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/Login" component={Login} />
        <Route exact path="/SignUp" component={SignUp} />
        <Route exact path="/Verification" component={Verification} />
        <Route exact path="/EventDetail" component={EventDetail} />
        <Route exact path="/Profile" component={Profile} />
        <Route exact path="/UserProfile" component={UserProfile} />
        <Route exact path="/Chat" component={Chat} />
        <Route exact path="/ViewMap" component={ViewMap} />
        <Route exact path="/Contact" component={ContactUs} />
        <Route exact path="/Requests" component={Requests} />
      </Switch>
    </div>
  );
}

export default App;
