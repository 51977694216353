import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { MDBSpinner } from "mdb-react-ui-kit";

const Login = () => {
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    const form = e.target;
    const formData = new FormData(form);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URI}/Login`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      setSubmitting(false);
      const responseData = response.data;
      if (responseData.message === "success") {
        Cookies.set("email", responseData.email, { expires: 2 });
        Cookies.set("login", true, { expires: 2 });
        Cookies.set("token", responseData.token, { expires: 2 });
        window.location.href = `/`;
      } else if (responseData.message === "invalid") {
        alert("INVALID EMAIL OR PASSWORD");
      }
    } catch (error) {
      console.error("Error:", error.message);
      setSubmitting(false);
    }
  };

  return (
    <div>
      <div class="super_container" style={{ minHeight: "100vh",maxHeight:'100vh' }}>
        <div class="contact">
          <div class="container">
            <div
              class="row"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div class="col-lg-4">
                <div class="contact_form_container">
                  <div class="contact_title" style={{color:'#000'}}>Log In</div>
                  <form
                    action="#"
                    class="contact_form"
                    id="contact_form"
                    onSubmit={handleSubmit}
                  >
                    <input
                      type="email"
                      class="contact_input"
                      placeholder="E-mail"
                      required="required"
                      name="email"
                    />
                    <input
                      type="password"
                      class="contact_input"
                      placeholder="Password"
                      required="required"
                      name="password"
                    />
                    <p>
                      Don't have an account{" "}
                      <a href="/SignUp" style={{ color: "#13b059" }}>
                        {" "}
                        Register
                      </a>
                    </p>
                    <button class="contact_button" type="submit">
                      {submitting?(<MDBSpinner/>):(<span>Log In</span>)}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
