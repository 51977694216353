import React, { useState } from "react";
import axios from "axios";
import { MDBSpinner } from "mdb-react-ui-kit";

const SignUp = () => {
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    const form = e.target;
    const formData = new FormData(form);
    
    // Check if the password and confirm password match
    if (
      document.getElementById("password").value ===
      document.getElementById("cpassword").value
    ) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_URI}/SignUp`,
          formData,
          {
            headers: {
              // Content-Type is automatically set to multipart/form-data for FormData
              "api-key": process.env.REACT_APP_API_KEY,
            },
          }
        );

        const responseData = response.data;
        setSubmitting(false);

        if (responseData.message === "User already registered.") {
          alert("User already registered.");
        }
        else if (responseData.message === "Invalid zip code. Registration not allowed.") {  
          alert("Invalid zip code. Registration not allowed.");
        }
         else {
          window.location.href = `/Verification?email=${responseData.email}&auth=${responseData.code}`;
        }
      } catch (error) {
        console.error("Error:", error.message);
        setSubmitting(false);
      }
    } else {
      setSubmitting(false);
      alert("Password and Confirm Password must Match");
    }
  };

  return (
    <div>
      <div className="super_container" style={{ minHeight: "100vh" }}>
        <div className="contact">
          <div className="container">
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="col-lg-4">
                <div className="contact_form_container">
                  <div className="contact_title" style={{ color: '#000' }}>Register</div>
                  <form
                    className="contact_form"
                    id="contact_form"
                    onSubmit={handleSubmit}
                  >
                    {/* Image Input */}
                    <p style={{color:'#000',textAlign:'left',fontWeight:'bold',fontSize:'16px', marginBottom:'0px'}}>Profile Image:</p>
                    <input
                      type="file"
                      // className="contact_input"
                      accept="image/*"
                      required
                      name="userimage"
                    />
                    <input
                      type="text"
                      className="contact_input"
                      placeholder="First Name"
                      required
                      name="firstname"
                    />
                    <input
                      type="text"
                      className="contact_input"
                      placeholder="Last Name"
                      required
                      name="lastname"
                    />
                    <input
                      type="email"
                      className="contact_input"
                      placeholder="E-mail"
                      required
                      name="email"
                    />
                    <input
                      type="password"
                      className="contact_input"
                      placeholder="Password"
                      required
                      name="password"
                      id="password"
                    />
                    <input
                      type="password"
                      className="contact_input"
                      placeholder="Confirm Password"
                      required
                      name="cpassword"
                      id="cpassword"
                    />
                    <input
                      type="number"
                      className="contact_input"
                      placeholder="Zip Code"
                      required
                      name="zipCode"
                      id="zipCode"
                    />
                    <p>
                      Already have an account{" "}
                      <a href="/Login" style={{ color: "#13b059" }}>
                        LogIn
                      </a>
                    </p>
                    <button className="contact_button" type="submit">
                      {submitting ? (<MDBSpinner />) : (<span>Register</span>)}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
