import React, { useEffect } from 'react';

const ViewMap = () => {
  // Get the URL parameters
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  // Access the 'data' parameter
  const data = JSON.parse(params.data);
  console.log(data);

  useEffect(() => {
    window.initMap = () => {
      // Calculate the bounds to fit all markers
      var bounds = new window.google.maps.LatLngBounds();

      // Create the map with the first location as the initial center
      var mapProp = {
        center: new window.google.maps.LatLng(data[0].latitude, data[0].longitude),
        zoom: 15,
      };
      var map = new window.google.maps.Map(
        document.getElementById('googleMap'),
        mapProp
      );

      // Loop through the data array and create markers
      data.forEach(item => {
        var marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(item.latitude, item.longitude),
          map: map,
          // title: 'Your Marker Title', // You can set a title for each marker
        });

        // Extend the bounds to include each marker's position
        bounds.extend(marker.getPosition());

        // Create an info window for each marker
        var infoWindow = new window.google.maps.InfoWindow({
          content: `<div><strong>${item.title}</strong><br>${item.location}</div><a href="/EventDetail?id=${item.id}">View More</a>`,
        });

        // Add event listener to show info window on marker click
        marker.addListener('click', () => {
          infoWindow.open(map, marker);
        });
      });

      // Fit the map to the bounds
      map.fitBounds(bounds);
    };

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCh39n5U-4IoWpsVGUHWdqB6puEkhRLdmI&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
      delete window.initMap;
    };
  }, [data]);

  return (
    <div className="map_container" style={{ marginTop: '-20px' }}>
      <div className="map">
        <div id="googleMap" style={{ height: '100vh', width: '100%', marginTop: '20px' }}></div>
      </div>
    </div>
  );
};

export default ViewMap;
