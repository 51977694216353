import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import "../styles/main_styles.css";
import "../styles/responsive.css";
import { Col, Row, Form, Container, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Map from "./Map";
import toast, { Toaster } from 'react-hot-toast';

import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';
import {
  MDBSpinner,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
} from "mdb-react-ui-kit";
import axios from "axios";

const EventDetail = () => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const response = await axios.get(
          `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
            query
          )}&key=7c3db7d1298a49ac8e3962fbf93dad32`
        );

        setResults(response.data.results);
        console.log(response.data.results);
      } catch (error) {
        console.error("Error fetching location data:", error);
      }
    };

    // Fetch location data only if the query is not empty
    if (query.trim() !== "") {
      fetchLocationData();
    }
  }, [query]);

  const handleRequestToJoin = async (eventId) => {
    try {
      // Retrieve email from cookies
      const email = Cookies.get('email'); // Replace 'email' with the actual cookie name

      if (!email) {
        toast.error('Email is required to request to join.');
        return;
      }

      // Prepare the data to be sent
      const requestData = {
        eventId,
        email,
      };

      // Send POST request
      const response = await axios.post(`${process.env.REACT_APP_URI}/RequestToJoin`, requestData);

      // Handle response
      console.log('Request successful:', response.data);
      toast.success('Request to join sent successfully.');
      fetchEventStatuses()
    } catch (error) {
      console.error('Error sending request to join:', error);
      toast.error('Failed to send request to join.');
    }
  };



  const isLocationWithinRange = (event, selectedOption, lat, long) => {
    const isLatitudeWithinRange = Math.abs(event.latitude - lat) <= 1;
    const isLongitudeWithinRange = Math.abs(event.longitude - long) <= 1;

    return isLatitudeWithinRange && isLongitudeWithinRange;
  };

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion.formatted);
    // You can perform additional actions when a suggestion is clicked
  };

  const [submit, setSubmit] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [basicModal, setBasicModal] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const [id, setid] = useState(urlParams.get("id"));
  const [data, setData] = useState([]);
  const [requests, setRequests] = useState([]);
  function logout() {
    Cookies.remove("login");
    Cookies.remove("email");
    Cookies.remove("token");
    window.location.href = "/";
  }

  useEffect(() => {

    fetchEventStatuses();
  }, [Cookies.get('email')]);

  const fetchEventStatuses = async () => {
    try {
      const email = Cookies.get('email'); // Replace 'email' with the actual cookie name
      const response = await fetch(`${process.env.REACT_APP_URI}/GetEventIdsRequest?email=${email}`);
      const result = await response.json();
      setRequests(result.data);
    } catch (error) {
      console.error('Error fetching event statuses:', error);
    }
  };


  useEffect(() => {
    document.body.style.overflowX = "hidden";
    window.scrollTo(0, 0);
    getProducts();
  }, [id]);

  const [hide, setHide] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);

    const form = e.target;
    const formData = new FormData(form);
    formData.append('hide', hide); // Add hide field to FormData

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URI}/AddEvent?email=${Cookies.get("email")}&latitude=${results[0].geometry.lat}&longitude=${results[0].geometry.lng}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      form.reset();
      setSubmit(false);
      setBasicModal(false);
    } catch (error) {
      console.error("Error:", error.message);
      setSubmit(false);
    }
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    console.log("Menu Open?", menuOpen); // Debugging line
  };


  async function getProducts() {
    setLoad(true)
    await fetch(`${process.env.REACT_APP_URI}/GetEventDetail?id=${id}`, {
      method: "GET",
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data.data);
        setData(data.data);
        setLoad(false)
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const [participants, setParticipants] = useState([]);
  const [modal, setModal] = useState(false);

  const handleButtonClick = async () => {
    try {
      // Fetch participants data from the API
      const response = await axios.get(`${process.env.REACT_APP_URI}/GetEventUsers?id=${id}`);
      setParticipants(response.data.data); // Update state with fetched data
      setModal(true); // Show modal
    } catch (error) {
      console.error("Error fetching participants:", error);
    }
  };

  if (load) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <MDBSpinner grow style={{ color: '#0aae4d' }}>
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
      </div>
    )
  }

  return (
    <div>
      <div class="super_container">
      <header class="header">
          <div class="header_content d-flex flex-row align-items-center justify-content-end">
            <div
              class="logo"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                window.location.href = "/";
              }}
            >
              <img
                src="../assets/logo.png"
                style={{ width: "60px", height: "60px" }}
                alt=""
              />
              <a href="/" style={{ marginLeft: "20px" }}>
                Boongombia
              </a>
            </div>
            <div class="log_reg">
              {Cookies.get("login") === "true" ? (
                <ul class="d-flex flex-row align-items-start justify-content-start">
                  <li>
                    <a
                      onClick={() => logout()}
                      style={{ color: "#FFF", cursor: "pointer" }}
                    >
                      Logout
                    </a>
                  </li>
                  <li>
                    {Cookies.get("login") === "true" ? (
                      <a
                        onClick={() => setBasicModal(true)}
                        style={{ color: "#FFF", cursor: "pointer" }}
                      >
                        Add Event
                      </a>
                    ) : (
                      <a href="/Login" style={{ color: "#FFF" }}>
                        Add Event
                      </a>
                    )}
                  </li>
                </ul>
              ) : (
                <ul class="d-flex flex-row align-items-start justify-content-start">
                  <li>
                    {Cookies.get("login") === "true" ? (
                      <a onClick={() => setBasicModal(true)}>Add Event</a>
                    ) : (
                      <a href="/Login">Add Event</a>
                    )}
                  </li>
                  <li>
                    <a href="/Login">Login</a>
                  </li>
                  <li>
                    <a href="/SignUp">Register</a>
                  </li>
                </ul>
              )}
            </div>
            <nav class="main_nav" style={{ marginRight: "200px" }}>
              <ul class="d-flex flex-row align-items-start justify-content-start">
                <li class="active">
                  <a href="/">Home</a>
                </li>
                {Cookies.get("login") === "true" ? (
                  <li>
                    <a href="/Chat">Chat</a>
                  </li>
                ) : null}

                <li>
                  <a href="/Contact">Contact</a>
                </li>
                {Cookies.get("login") === "true" ? (
                  <li>
                    <a href="/Profile">Profile</a>
                  </li>
                ) : null}
              </ul>
            </nav>
            <div class="hamburger ml-auto" onClick={toggleMenu}>
              <div class="d-flex flex-column align-items-end justify-content-between">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </header>

        <div className={`menu ${menuOpen ? "active" : ""}`}>
          <div class="menu_overlay" onClick={()=>{setMenuOpen(false)}}></div>
          <div class="menu_container d-flex flex-column align-items-start justify-content-center">
            <div class="menu_log_reg">
              {Cookies.get("login") === "true" ? (
                <ul class="d-flex flex-row align-items-start justify-content-start">
                  <li>
                    <a
                      onClick={() => logout()}
                      style={{ color: "#000", cursor: "pointer" }}
                    >
                      Logout
                    </a>
                  </li>
                  <li>
                    {Cookies.get("login") === "true" ? (
                      <a
                        onClick={() => setBasicModal(true)}
                        style={{ color: "#000", cursor: "pointer" }}
                      >
                        Add Event
                      </a>
                    ) : (
                      <a href="/Login" style={{ color: "#000" }}>
                        Add Event
                      </a>
                    )}
                  </li>
                </ul>
              ) : (
                <ul class="d-flex flex-row align-items-start justify-content-start">
                  <li>
                    <a>
                      {Cookies.get("login") === "true" ? (
                        <a onClick={() => setBasicModal(true)}>Add Event</a>
                      ) : (
                        <a href="/Login">Add Event</a>
                      )}
                    </a>
                  </li>
                  <li>
                    <a href="/Login">Login</a>
                  </li>
                  <li>
                    <a href="/SignUp">Register</a>
                  </li>
                </ul>
              )}
            </div>
            <nav class="menu_nav">
              <ul class="d-flex flex-column align-items-start justify-content-start">
                <li>
                  <a href="/">Home</a>
                </li>
                {Cookies.get("login") === "true" ? (
                  <li>
                    <a href="/Chat">Chat</a>
                  </li>
                ) : null}

                <li>
                  <a href="/Contact">Contact</a>
                </li>
                {Cookies.get("login") === "true" ? (
                  <li>
                    <a href="/Profile">Profile</a>
                  </li>
                ) : null}
              </ul>
            </nav>
          </div>
        </div>

        <Col
          lg={10}
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "100px",
            marginBottom: "100px",
          }}
        >
          <div className="rounded-4 mb-3">
            {data.map((d, index) => {
              // Find the status of the current event
              const requestStatus = requests.find((request) => request.eventId === d.id)?.status;

              let buttonText = 'Request To Join';
              let isDisabled = false;

              if (requestStatus === null) {
                buttonText = 'Requested';
                isDisabled = true;
              } else if (requestStatus === 'Approved') {
                buttonText = 'Approved';
                isDisabled = true;
              } else if (requestStatus === 'Rejected') {
                buttonText = 'Rejected';
                isDisabled = true;
              }

              return (
                <div>
                  <img
                    style={{
                      width: "100%",
                      maxHeight: "500px",
                      // width: "auto",
                      height: "auto",
                    }}
                    src={`${process.env.REACT_APP_URI}/images/${d.image}`}
                  />

                  <div style={{ marginTop: "20px" }}>
                    {d.email === Cookies.get("email") ? (
                      <img
                        style={{ width: "50px", borderRadius: "50%" }}
                        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                          d.firstname[0]
                        )}&size=50`}
                        alt="Avatar"
                        onClick={() => {

                          window.location.href = `/Profile?id=${d.userId}`;
                        }}
                      />
                    ) : (
                      <img
                        style={{ width: "50px", borderRadius: "50%" }}
                        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                          d.firstname[0]
                        )}&size=50`}
                        alt="Avatar"
                        onClick={() => {
                          Cookies.set("useremail", d.email, { expires: 2 });
                          window.location.href = `/UserProfile?id=${d.userId}`;
                        }}
                      />
                    )}

                    <h2>{d.title}</h2>

                    <div className="mb-3">
                      <span className="h5">
                        {d.location}{" "}
                        <span style={{ color: "green" }}>({d.date})</span>
                      </span>
                      <p style={{ textAlign: "justify", margin: "20px" }}>
                        {d.description}
                      </p>
                    </div>
                  </div>
                  <button class="contact_button" type="submit" onClick={() => !isDisabled && handleRequestToJoin(d.id)}>
                    {buttonText}
                  </button><br />
                  <button style={{ color: 'blue', border: 'none', background: '#fff', marginTop: '10px' }} onClick={()=>{handleButtonClick()}}>
                    Event Participants
                  </button>
                  {d.hide && !buttonText === 'Approved' ? (<p style={{ color: '#0aae4d' }}> Join to See Location</p>) : (
                    <div>
                      <Map latitude={d.latitude} longitude={d.longitude} />
                    </div>
                  )}

                </div>
              );
            })}
          </div>
        </Col>

        <footer class="footer">
          <div class="footer_container d-flex flex-xl-row flex-column align-items-start justify-content-center">
            <div class="newsletter_container" >
              <img src="../assets/logo1.png" alt="" style={{ maxWidth: '200px', marginBottom: '20px' }} />

              <div class="newsletter_title">
                <h2 style={{ color: '#0aae4d' }}>Subscribe to our newsletter</h2>
              </div>
              <form action="#" id="newsletter_form" class="newsletter_form" >
                <input
                  type="email"
                  class="newsletter_input"
                  placeholder="Your E-mail"
                  required="required"
                  style={{ backgroundColor: "#FFF" }}
                />
                <button class="newsletter_button">Subscribe</button>
              </form>
            </div>
            <div
              class="footer_lists d-flex flex-sm-row  flex-column align-items-start justify-content-center ml-xl-auto"
              style={{ marginBottom: "40px" }}
            >
              <div class="footer_list">
                <div class="footer_list_title" style={{ color: '#0aae4d' }}>Useful Links</div>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  {Cookies.get("login") === "true" ? (
                    <li>
                      <a href="/Chat">Chat</a>
                    </li>) : null
                  }

                  <li>
                    <a href="/Contact">Contact</a>
                  </li>
                  <li>
                  {Cookies.get("login") === "true" ? (
                      <a onClick={() => setBasicModal(true)}>Add Event</a>
                    ) : (
                      <a href="/Login">Add Event</a>
                    )}
                  </li>
                </ul>
              </div>

              <div class="footer_list">
                <div class="footer_list_title" style={{ color: '#0aae4d' }}>Boongombia</div>
                <ul style={{ display: 'flex', justifyContent: 'center', gap: '20px', padding: '0px' }}>
                  <li>
                    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                      <FaFacebook />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                      <FaTwitter />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                      <FaLinkedin />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                      <FaInstagram />
                    </a>
                  </li>
                </ul>
              </div>

            </div>
          </div>
          <div class="copyright_bar" style={{ marginTop: '50px' }}>
            <span>Copyright &copy; 2024 All rights reserved</span>
          </div>
        </footer>
      </div>



      <MDBModal show={modal} setShow={setModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Event Participants</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={() => setModal(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {participants.length > 0 ? (
                <ul style={{padding:'0px'}}>
                {participants.map((participant) => (
                  <li
                    key={participant.id}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',  // Add this line for equal spacing
                      marginBottom: '10px',
                      borderBottom: '1px solid #ccc',
                      padding: '10px 0',  // Add padding for better spacing
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_URI}/images/${participant.userimage}`}
                      alt={`${participant.firstname} ${participant.lastname}`}
                      style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%',
                        marginRight: '10px',
                      }}
                    />
                    <div style={{ flexGrow: 1 }}>  {/* This ensures the name and email take the remaining space */}
                      <p style={{ margin: 0, fontWeight: 'bold' }}>{`${participant.firstname} ${participant.lastname}`}</p>
                    </div>
                    <div style={{ flexGrow: 2 }}>
                    <p style={{ margin: 0 }}>{participant.email}</p>

                    </div>
                  </li>
                ))}
              </ul>
              
              ) : (
                <p>No participants found.</p>
              )}
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>



      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
        <MDBModalDialog style={{ borderRadius: 0 }}>
          <MDBModalContent id="card">
            <MDBModalHeader>
              <MDBModalTitle>Add Event</MDBModalTitle>
              <MDBBtn
                style={{ color: "white" }}
                className="btn-close"
                color="none"
                onClick={() => setBasicModal(!basicModal)}
              ></MDBBtn>
            </MDBModalHeader>
            <form onSubmit={handleSubmit} encType="multipart/form-data" id="Coursesform">
              <MDBModalBody>
                <Form.Group className="mb-3">
                  <label htmlFor="imageInput">Select an Image: {"   "}</label>
                  <input
                    type="file"
                    id="imageInput"
                    name="image"
                    accept="image/*"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <input
                    type="text"
                    className="newsletter_input"
                    placeholder="Title"
                    name="title"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <textarea
                    style={{
                      width: "100%",
                      height: "120px",
                      background: "rgba(0, 0, 0, 0.2)",
                      border: "none",
                      outline: "none",
                      paddingLeft: "23px",
                      color: "#000",
                      fontSize: "12px",
                      fontStyle: "italic",
                    }}
                    placeholder="Description"
                    name="description"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <input
                    type="text"
                    className="newsletter_input"
                    placeholder="Location"
                    name="location"
                    autoComplete="off"
                    required
                    value={query}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <div
                    className="newsletter_input text-start"
                    style={{ paddingLeft: "0px" }}
                  >
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      dateFormat="MM/dd/yyyy"
                      className="newsletter_input2"
                      name="date"
                      isClearable
                      placeholderText="Select a date"
                      autoComplete="off"
                    />
                  </div>
                </Form.Group>
                {/* Hide Switch */}
                <Form.Group className="mb-3">
                  <Form.Check
                    type="switch"
                    id="hideSwitch"
                    label="Hide"
                    checked={hide}
                    onChange={() => setHide(!hide)}
                  />
                </Form.Group>
              </MDBModalBody>

              <MDBModalFooter style={{ padding: "40px" }}>
                <button
                  type="submit"
                  className="newsletter_button"
                  style={{ marginTop: "15px", marginRight: "15px" }}
                >
                  {submit ? <MDBSpinner color="info" /> : <span>Submit</span>}
                </button>
              </MDBModalFooter>
            </form>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
};

export default EventDetail;
